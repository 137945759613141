import React, {Component} from 'react'
import Link from '../utils/link'
import {Logo, DropdownArrow, Instagram, Facebook, Linkedin, ArrowRight, ArrowThickLeft, TikTok} from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    offCanvasDropdown: false,
  }

  _hideOffCanvas = () => {
    this.setState({offCanvas: false})
  }

  _toggleOffCanvas = () => {
    this.setState({offCanvas: !this.state.offCanvas})
    this.setState({offCanvasDropdown: false})
  }

  render() {

    let {offCanvas, offCanvasDropdown} = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    return (
      <>
        <header className='header'>
          <div className='header__inner'>
            <Link to='/' title='Heart Financial' className='header__logo' {...props}>
              <Logo/>
            </Link>
            <nav className='header__nav'>
              <ul>
                <li className='header__has-dropdown'>
                  <Link to='#'>Services <DropdownArrow/></Link>
                  <ul className='header__dropdown'>
                    <li><Link to='/service/home-loans/' {...props}>Home Loans</Link></li>
                    <li><Link to='/service/investment-loans/' {...props}>Investment Loans</Link></li>
                    <li><Link to='/service/commercial-loans/' {...props}>Commercial Loans</Link></li>
                    <li><Link to='/service/smsf/' {...props}>SMSF</Link></li>
                    <li><Link to='/service/car-loans/' {...props}>Car Loans</Link></li>
                    <li><Link to='/service/personal-loans/' {...props}>Personal Loans</Link></li>
                    <li><Link to='/service/equipment-finance/' {...props}>Equipment Finance</Link></li>
                    <li><Link to='/service/wellness/' {...props}>Financial Wellness</Link></li>
                  </ul>
                </li>
                <li><Link to='/resources/' {...props}>Resources</Link></li>
                <li><Link to='/about-us/' {...props}>About</Link></li>
                <li><Link to='/reviews/' {...props}>Reviews</Link></li>
                <li><Link to='/team/' {...props}>Team</Link></li>
                <li><Link to='/news/' {...props}>News & Events</Link></li>
                <li><Link className='btn btn--mauve' to='/contact/' {...props}>Get in touch</Link></li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas}
                    className={offCanvas ? 'header__hamburger active' : 'header__hamburger'}>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`}>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link className='btn btn--mauve' to='/contact/' {...props}>Get in Touch</Link></li>
                <li>
                  <Link
                    onClick={() => this.setState({offCanvasDropdown: !this.state.offCanvasDropdown})}>Services <ArrowRight/></Link>
                  <ul className={`off-canvas__dropdown ${offCanvasDropdown && 'off-canvas__dropdown-active'}`}>
                    <button onClick={() => this.setState({offCanvasDropdown: false})}><ArrowThickLeft/>Back to Main
                    </button>
                    <li><Link to='/service/home-loans/' {...props}>Home Loans</Link></li>
                    <li><Link to='/service/investment-loans/' {...props}>Investment Loans</Link></li>
                    <li><Link to='/service/commercial-loans/' {...props}>Commercial Loans</Link></li>
                    <li><Link to='/service/smsf/' {...props}>SMSF</Link></li>
                    <li><Link to='/service/car-loans/' {...props}>Car Loans</Link></li>
                    <li><Link to='/service/personal-loans/' {...props}>Personal Loans</Link></li>
                    <li><Link to='/service/equipment-finance/' {...props}>Equipment Finance</Link></li>
                    <li><Link to='/service/wellness/' {...props}>Financial Wellness</Link></li>
                  </ul>
                </li>
                <li><Link to='/resources/' {...props}>Resources</Link></li>
                <li><Link to='/about-us/' {...props}>About <ArrowRight/></Link></li>
                <li><Link to='/team/' {...props}>Team</Link></li>
                <li><Link to='/news/' {...props}>News & Events</Link></li>
              </ul>
            </nav>
            <div className='off-canvas__footer'>
              <Link to='tel:03 8060 4750'>03 8060 4750</Link>
              <Link to='mailto:hello@heartfinancial.com.au'>hello@heartfinancial.com.au</Link>
              <div className='off-canvas__footer-socials'>
                <ul>
                  <li><Link to='https://www.instagram.com/heartfinancialaus/'><Instagram color='#000'/></Link></li>
                  <li><Link to='https://www.facebook.com/heartfinancialau'><Facebook color='#000'/></Link></li>
                  <li><Link to='https://www.tiktok.com/@heartfinancial'><TikTok color='#000'/></Link></li>
                  <li><Link to='https://au.linkedin.com/company/heartfinancial'><Linkedin color='#000'/></Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
