import React, {Component} from 'react'
import Link from '../utils/link'
import {Instagram, Facebook, Linkedin, TikTok} from "./icons";

class Footer extends Component {

  render() {
    return (
      <footer className='footer'>
        <div className='footer__inner'>
          <div className='footer__top'>
            <div className='footer__detail'>
              <Link to='tel:+61380604750'>03 8060 4750</Link>
              <Link to='mailto:hello@heartfinancial.com.au'>hello@heartfinancial.com.au</Link>
              <ul>
                 <li><Link to='https://www.instagram.com/heartfinancialaus/'><Instagram color='#000'/></Link></li>
                 <li><Link to='https://www.facebook.com/heartfinancialau'><Facebook color='#000'/></Link></li>
                 <li><Link to='https://www.tiktok.com/@heartfinancial'><TikTok color='#000'/></Link></li>
                 <li><Link to='https://au.linkedin.com/company/heartfinancial'><Linkedin color='#000'/></Link></li>
              </ul>
              <div className='footer__items'>
                <div className='footer__item'>
                  <h3>Our office</h3>
                  <span>
                    Suite 3, Level 1<br/>8 Eddy Street Moonee Ponds<br/>VIC 3039</span>
                </div>
                <div className='footer__item'>
                  <h3>Mail</h3>
                  <span>P.O Box 573<br/>Moonee Ponds<br/>VIC 3039</span>
                </div>
              </div>
            </div>

            <div className='footer__menu'>
              <ul>
                <li><Link to='/about-us/'>About</Link></li>
                <li><Link to='/team'>Team</Link></li>
                <li><Link to='/resources/'>Resources</Link></li>
                <li><Link to='/reviews/'>Reviews</Link></li>
                <li><Link to='https://bookings.heartfinancial.com.au/portal-embed#/heartfinancial'>Book a Meeting</Link></li>
                <li><Link to='/contact/'>Contact Us</Link></li>
              </ul>
              <ul>
                <h3>Services</h3>
                   <li><Link to='/service/home-loans/'>Home Loans</Link></li>
                    <li><Link to='/service/investment-loans/'>Investment Loans</Link></li>
                    <li><Link to='/service/commercial-loans/'>Commercial Loans</Link></li>
                    <li><Link to='/service/smsf/'>SMSF</Link></li>
                    <li><Link to='/service/car-loans/' >Car Loans</Link></li>
                    <li><Link to='/service/personal-loans/'>Personal Loans</Link></li>
                    <li><Link to='/service/equipment-finance/'>Equipment Finance</Link></li>
                    <li><Link to='/service/wellness/'>Financial Wellness</Link></li>
              </ul>
            </div>
          </div>

          <div className='footer__bottom'>
            <div className='footer__detail'>
              <p>Jo Attard + Co Pty Ltd trading as Heart Financial Group is a Corporate <br/> Credit Representative (416289) of Mortgage Specialists Pty Ltd.</p>
              <p>Australian Credit Licence 387250 <br/>ABN 13 148 905 828</p>
            </div>

            <div className='footer__credit'>
              <div className='footer__col'>
                <Link to='/privacy-policy/'>Privacy Policy</Link>
                <Link to='/credit-guide/'>Credit Guide</Link>
              </div>

              <div className='footer__col'>
                <Link to='/'>© Heart Financial 2024</Link>
                <Link to='https://atollon.com.au/'>Design by Atollon</Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
